.section
  background-color: $white
  padding: 3rem 1.5rem
  // Responsiveness
  +desktop
    // Sizes
    &.is-medium
      padding: 9rem 1.5rem
    &.is-large
      padding: 18rem 1.5rem
