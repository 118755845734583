	body {
	  font-family: 'Karla', sans-serif;
	  font-family: 'Montserrat', sans-serif;
	  letter-spacing: 1px;
	  font-size: 17px;
	  font-weight: 300;
	  line-height: 1.6;
	  color: $text-color;
	  color: blue;
	  @include mobile() {
	  	font-size: 15px;
	  }
	}
.content {
	h1 {
		font-family: 'Playfair Display', sans-serif;
		color: #3a4048;
		font-weight: 400;
		letter-spacing: 2px;
		line-height: 1.6;
	}

	h2,h3,h4,h5 {
		font-family: 'Montserrat', sans-serif;
			color: #3a4048;
		font-weight: 300;
		letter-spacing: 2px;
		line-height: 1.6;

	}

	h1 {
		font-size: 24px;
		@include mobile() {
			font-size: 18px;
		}
	}
}