
.home--intro {
  height: 70vh;
  position: relative;
  overflow: hidden;
  .slick-slider {
    height: 100%;
    .slick-slide {
     background-size: cover;
      background-position: center center;
      height: 70vh;
      @include mobile() {
        background-size: cover;
      }
    }
    img {
      visibility: hidden;
    }

  }
  .home--intro-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    svg {
      max-width: 80%;
      @include mobile() {
        display: none;
      }
    }
    .button {
      margin-top: 30px;
      // color: white;
      // 
      // font-weight: 700;
      // .button-icon {
      //   color: white;
      //   border-color: white;
      //   &:before, &:after {
      //     background: white;
      //   }
      // }
      // @include tablet() {
      //    &:hover {
      //     background: transparent;

      //   }
      // }
     
    }
  }
    @include mobile() {
       height: 50vh;
       .slick-slider {
        .slick-slide {
          height: 50vh;
      }
     }
    }
}

.home--about {
 
  text-align: center;
  position: relative;
  padding: 75px 30px 0 30px;
  @include tablet() {
    height: 30vh;
     padding: 75px;
  }
  .home--about-inner {
    @include tablet() {
        width: 90%;
       position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
   
  }
}