
.header {
  .logo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 200px;
    top: 30px;
    left: 30px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .navigation--toggle {
    width: 75px;
    height: 75px;
    background: white;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 10;
    display: block;
    .line {
      position: absolute;
      width: 40%;
      height: 1px;
      background: black;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease;
      &:first-child {
        top: 45%;
      }
      &:last-child {
        top: 55%;
      }
    }
    &:hover {
      .line {
        &:first-child {
          top: 40%;
        }
        &:last-child {
          top:60%;
        }
      }
    }
    &.in {
      .line {
        &:first-child {
          top: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
        &:last-child {
          top: 50%;
          transform: translateX(-50%) rotate(-45deg);
        }
      }
    }
  }

  &.headroom--not-top {
    .logo {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
}

.navigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: white;
  padding: 30px;
  z-index: 9;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  @include desktop() {
    width: 300px;
  }

  ul {
    width: 80%;
    position: absolute;
    right: 40px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 0;
    margin: 0;
    list-style:none;
    text-align: right;
    li {
      transition: transform 0.3s ease, opacity 0.3s ease;
      opacity: 0;
      transform: translateX(50px);
      
    }
    a {
      color: $text-color;
      font-size: 22px;
      line-height: 3.5;
      display: block;
      // text-transform: uppercase;
      &:hover {
        color: darken($text-color, 10%);
      }
    }
  }
  &.in {
    transform: translateX(0);
    ul {
      li {
        opacity: 1;
        transform: translateX(0);
        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            transition-delay: calc(#{$i}s*0.1);
          }
        }
      }
    }
  }
}
