.breadcrumb
  +block
  +unselectable
  align-items: stretch
  display: flex
  font-size: $size-normal
  overflow: hidden
  overflow-x: auto
  white-space: nowrap
  a
    align-items: center
    color: $text-light
    display: flex
    justify-content: center
    padding: 0.5em 0.75em
    &:hover
      color: $link-hover
  li
    align-items: center
    display: flex
    &.is-active
      a
        color: $text-strong
        cursor: default
        pointer-events: none
    & + li:before
      color: $text
      content: '\0002f'
  ul, ol
    align-items: center
    display: flex
    flex-grow: 1
    flex-shrink: 0
    justify-content: flex-start
  .icon
    &:first-child
      margin-right: 0.5em
    &:last-child
      margin-left: 0.5em
  // Alignment
  &.is-centered
    ol, ul
      justify-content: center
  &.is-right
    ol, ul
      justify-content: flex-end
  // Sizes
  &.is-small
    font-size: $size-small
  &.is-medium
    font-size: $size-medium
  &.is-large
    font-size: $size-large
  // Styles
  &.has-arrow-separator
    li + li:before
      content: '\02192'
  &.has-bullet-separator
    li + li:before
      content: '\02022'
  &.has-dot-separator
    li + li:before
      content: '\000b7'
  &.has-succeeds-separator
    li + li:before
      content: '\0227B'
