$icon-font-path: '../fonts/';
@import 'vars';

  @import '../assets/bower_components/bulma/bulma';

// bower:scss
@import "../assets/bower_components/slick-carousel/slick/slick.scss";
@import "../assets/bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "../assets/bower_components/slick-carousel/slick/slick-theme.scss";
 

@import 'components/type';
@import 'components/navigation';
@import 'components/button';

@import 'components/footer';
@import 'components/global';
@import 'components/form';
@import 'components/nl-form';

@import 'pages/home';


@keyframes logoLoad {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.loading-screen {
  position: fixed;
    background: white;
    top: 0;
    left: 0;
    // visibility: hidden;
    // opacity: 0;
    transition: all 0.6s ease;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform: translateX(100%);

    .loading--logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        // transition: opacity 0.3s ease;
        svg{
            max-width: 80%;
        }

    }
	&.active {

		opacity: 1;
		transform: translateX(0);
		visibility: visible;
        .loading--logo {
             // animation: logoLoad 1s linear infinite;
        }
	}
}