.button {
	&.is-large {
		padding: 0 60px;
		border-radius: 0;
		// border: 0;
		height: 2.25rem;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: 16px;
	}
	&.is-transparent {
		background: transparent;
		border: 0;
		position: relative;
		padding-right: 50px;
		color: #000;
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: 2px;
		.button-icon {
			position: absolute;
			top: 50%;
			right: 0;
			padding: 5px;
			height: 30px;
			width: 30px;
			border: thin solid #000;
			transform: translateY(-50%);
			border-radius: 20px;
			&:after, &:before {
				content: ' ';
				display:block;
				width: 10px;
				height: 1px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateY(-50%) translateX(-50%);
				background: #000;
				transition : transform 0.3s ease;
				transform-origin: 50% 50%;
			}
			&:after {
				transform: translateY(-50%) translateX(-50%) rotate(-90deg);
			}
		}

		&:hover {
			.button-icon {
				

				&:after {
					transform: translate(-50%,-50%) rotate(0);
				}
			}
		}
		
	}
}