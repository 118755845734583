.footer {
	text-align: center;
	text-transform: uppercase;
	padding: 0;
	margin: 50px 0;
	background: white;
	@include tablet() {
		margin: 150px 0 50px;
	}
	.footer--pod {
		display: block;
		@include mobile() {
			padding-bottom: 50px;
		}
	}
	.sub--footer {
		margin-top: 100px;
		background: #F7F6F5;
		padding: 50px 0;
		.logo {
			margin: 0 auto;
			max-width: 300px;
			svg {
				width: 100%;
				height: auto;
			}
		}
	}

}