// Display

$displays: 'block' 'flex' 'inline' 'inline-block' 'inline-flex'

@each $display in $displays
  .is-#{$display}
    display: #{$display}
  .is-#{$display}-mobile
    +mobile
      display: #{$display} !important
  .is-#{$display}-tablet
    +tablet
      display: #{$display} !important
  .is-#{$display}-tablet-only
    +tablet-only
      display: #{$display} !important
  .is-#{$display}-touch
    +touch
      display: #{$display} !important
  .is-#{$display}-desktop
    +desktop
      display: #{$display} !important
  .is-#{$display}-desktop-only
    +desktop-only
      display: #{$display} !important
  .is-#{$display}-widescreen
    +widescreen
      display: #{$display} !important

// Float

.is-clearfix
  +clearfix

.is-pulled-left
  float: left

.is-pulled-right
  float: right

// Overflow

.is-clipped
  overflow: hidden !important

// Overlay

.is-overlay
  +overlay

// Text

.has-text-centered
  text-align: center

.has-text-left
  text-align: left

.has-text-right
  text-align: right

@each $name, $pair in $colors
  $color: nth($pair, 1)
  .has-text-#{$name}
    color: $color
  a.has-text-#{$name}
    &:hover,
    &:focus
      color: darken($color, 10%)

// Visibility

.is-hidden
  display: none !important

.is-hidden-mobile
  +mobile
    display: none !important

.is-hidden-tablet
  +tablet
    display: none !important

.is-hidden-tablet-only
  +tablet-only
    display: none !important

.is-hidden-touch
  +touch
    display: none !important

.is-hidden-desktop
  +desktop
    display: none !important

.is-hidden-desktop-only
  +desktop-only
    display: none !important

.is-hidden-widescreen
  +widescreen
    display: none !important

// Other

.is-marginless
  margin: 0 !important

.is-paddingless
  padding: 0 !important

.is-unselectable
  +unselectable
