main.content {
	overflow: hidden;
	margin-bottom: 0;
}

@keyframes imageheader {
	0% { background-size: 100%;}
	100% { background-size: 120%};
}

.image--header {
	
	position: relative;
	background-size: cover;
	background-position: center center;
	
	@include mobile() {
		background: 0 !important;
	}

	@include tablet() {
		height: 50vh;
		margin-bottom: 200px;

		img {
			visibility: hidden;
		}
	}

	@include desktop() {
		margin-bottom: 50px;
	}
	
	.container {
		position: relative;
		height: 100%;
	}
	.caption {
		@include tablet() {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				transform: translateY(1px);
		}
	
	
		.columns {
			margin-bottom: 0;
		}

		.page-description {
			@include desktop(){
				transform: translateY(-1px);
			}
			
		}
		.text {
			padding: 20px 20px 20px 20px;
			background: white;
			@include tablet() {
				padding: 20px 100px 20px 20px;
			}
			@include desktop() {
				padding: 20px 100px 20px 0px;
			}
			h1 {
				margin-bottom: 0;
				font-size: 32px;
				@include widescreen() {
					font-size: 62px;
				}
				

			}
			&:before {
				content: ' ';
				display: block;
				position: absolute;
				top: 0;
				width: 2000px;
				transform: translateX(-100%);
				height: 100%;
				background: white;
			}
		}
	}
}
.img-responsive {
	width: 100%;
	height: auto;
}
.content--section {
	padding: 0px 0;
	img {
		width: 100%;
		height: auto;
	}
	@include tablet() {
		padding: 0px 0;
		&:nth-child(odd) {
			.columns {
				flex-direction: row-reverse;
				.centered {
					left: auto;
					right: 30px;
					text-align: right;

				}
			}
		}

	}
	
	.column {
		position: relative;
		.centered {
			padding: 60px 40px;
		}
		@include tablet() {
			.centered {
				padding: 0;
					position: absolute;
					top: 50%;
					left: 30px;
					transform: translateY(-50%);
					&.content--inner {
						padding: 50px;
					}
				}
		}
		
	}
}

