.field {
	.label {
		display: none;
	}
	.control {
		.input, .textarea {
			border: 0;
			background: darken(#fff, 5%);
			border-radius: 0;
			height: 3.5rem;
			box-shadow: none;
			font-size: 1.2rem;
		}
	}
	.button {
		&.is-full {
			width: 100%;
			height: 3rem;
		}
	}
}
