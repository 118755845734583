.icon
  align-items: center
  display: inline-flex
  justify-content: center
  height: 1.5rem
  width: 1.5rem
  .fa
    font-size: 21px
  // Sizes
  &.is-small
    height: 1rem
    width: 1rem
    .fa
      font-size: 14px
  &.is-medium
    height: 2rem
    width: 2rem
    .fa
      font-size: 28px
  &.is-large
    height: 3rem
    width: 3rem
    .fa
      font-size: 42px
